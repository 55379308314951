import {
  StorageUnitSizeFragment,
  useStorageUnitSizesQuery,
} from 'types/generated/graphql';

const getVolume = (size: StorageUnitSizeFragment) =>
  size.innerDepth * size.innerHeight * size.innerWidth;

export const useBiggestStorageUnitSize = () => {
  const { data, ...rest } = useStorageUnitSizesQuery(
    {},
    {
      select: (data) =>
        data?.storageUnitSizes?.sort((a, b) => getVolume(b) - getVolume(a))[0],
    }
  );

  return {
    biggestStorageUnitSize: data,
    ...rest,
  };
};
