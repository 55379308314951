import { AUTH_CLIENT_ID, CUSTOM_AUTH_TOKEN, IS_KIOSK } from 'config';
import { useDeviceAuthStore } from 'utils/device-auth/device-auth-store';
import env from 'utils/env/env';
import { logger } from 'utils/logging/init';

export const getToken = () => {
  if (CUSTOM_AUTH_TOKEN) {
    return CUSTOM_AUTH_TOKEN;
  }
  if (IS_KIOSK) {
    return useDeviceAuthStore.getState().token;
  }

  const localStorageRecordAsString = localStorage.getItem(
    `oidc.user:${env.VITE_IDENTITY_API_URL}:${AUTH_CLIENT_ID}`
  );

  if (!localStorageRecordAsString) {
    return null;
  }

  try {
    const record = JSON.parse(localStorageRecordAsString);
    return record?.access_token;
  } catch (error: unknown) {
    logger.error(JSON.stringify(error));
    return null;
  }
};
