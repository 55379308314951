import { Alert, Button, Flex, Heading, styled } from '@conteg/ui';
import { useTranslation } from 'react-i18next';
import { NavigateFunction } from 'react-router-dom';
import env from 'utils/env/env';
import { redirect } from 'utils/routing/redirect';
import { appRoutes } from 'utils/routing/routes';

type ErrorProps = {
  message: string;
  navigate?: NavigateFunction;
  showToUnitListButton?: boolean;
  showToCustomerAppButton?: boolean;
};

const ErrorWrapper = styled.div`
  max-width: 150rem;
  margin: 4rem auto;
`;

const Error = ({
  message,
  navigate,
  showToUnitListButton,
  showToCustomerAppButton,
}: ErrorProps) => {
  const { t } = useTranslation();

  return (
    <ErrorWrapper>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap="4rem"
      >
        <Heading variant="h1" title={t('Error.Component.Heading')} />
        <Alert
          testId="storage-unit-detail-error"
          type="error"
          message={t(message)}
        />
        {showToUnitListButton ? (
          <Button
            testId="back-to-units-list"
            type="button"
            variant="primary"
            title={t('Error.Component.Button.ToUnitList')}
            onClick={() => (navigate ? navigate(appRoutes.home) : null)}
          />
        ) : null}
        {showToCustomerAppButton ? (
          <Button
            testId="back-to-customer-app"
            variant="primary"
            size="xl"
            title={t('Error.Component.Button.ToCustomerApp')}
            onClick={() => redirect(env.VITE_WORKFLOW_WEB_URL)}
          />
        ) : null}
      </Flex>
    </ErrorWrapper>
  );
};

export default Error;
