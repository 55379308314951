import {
  loginInit,
  loginPolling,
  logout,
  revokeToken,
} from 'utils/device-auth/auth-functions';
import { AuthStore } from 'utils/device-auth/types';
import create from 'zustand';

export const useDeviceAuthStore = create<AuthStore>((set, get) => ({
  loginData: null,
  token: '',
  expiresIn: 0,
  isAuthenticated: false,
  status: 'idle',
  error: '',
  actions: {
    setError: (error: string) => {
      set(() => ({
        loginData: null,
        token: '',
        expiresIn: 0,
        isAuthenticated: false,
        status: 'error',
        error,
      }));
    },
    loginInit: async (forceInit?: boolean) => {
      await loginInit(set, get, forceInit);
    },
    loginPolling: async () => {
      await loginPolling(set, get);
    },
    revokeTokenAfterExpiration: () => {
      revokeToken(set, get);
    },
    logout: async () => {
      await logout(set, get);
    },
  },
}));

export const useIsAuthenticated = () =>
  useDeviceAuthStore((state) => state.isAuthenticated);
export const useLoginData = () =>
  useDeviceAuthStore((state) => state.loginData);
export const useToken = () => useDeviceAuthStore((state) => state.token);
export const useStatus = () => useDeviceAuthStore((state) => state.status);
export const useError = () => useDeviceAuthStore((state) => state.error);
export const useActions = () => useDeviceAuthStore((state) => state.actions);
