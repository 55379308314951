import {
  Button,
  ButtonProps,
  ConfirmModal,
  Flex,
  Heading,
  Modal,
} from '@conteg/ui';
import Notification from 'components/notification/notification';
import { PolicyGuardWrapper } from 'components/policy-guard/policy-guard';
import { IS_KIOSK } from 'config';
import ActionsModalContent from 'pages/storage-unit-detail/components/actions/actions-modal-content';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StorageUnitDtoFragment,
  StorageUnitStatus,
} from 'types/generated/graphql';
import { PolicyAction } from 'types/policies-types';

export enum OpenModalTypes {
  OpenStorageUnit = 'openStorageUnit',
  RequestMaintenance = 'requestMaintenance',
  UpdateStatus = 'updateStatus',
  ShowOtp = 'showOtp',
  TerminateAllocation = 'terminateAllocation',
  ResendSms = 'resendSms',
  OpenStorageUnitWithoutPin = 'openStorageUnitWithoutPin',
  UpdateReservationState = 'updateReservationState',
  ChangeBlockingReservationTenantSubjectId = 'changeBlockingReservationTenantSubjectId',
  ForcedOpenStorageUnitWithReservation = 'forcedOpenStorageUnitWithReservation',
}

export type OpenModal =
  | OpenModalTypes.OpenStorageUnit
  | OpenModalTypes.RequestMaintenance
  | OpenModalTypes.UpdateStatus
  | OpenModalTypes.ShowOtp
  | OpenModalTypes.TerminateAllocation
  | OpenModalTypes.ResendSms
  | OpenModalTypes.OpenStorageUnitWithoutPin
  | OpenModalTypes.UpdateReservationState
  | OpenModalTypes.ChangeBlockingReservationTenantSubjectId
  | OpenModalTypes.ForcedOpenStorageUnitWithReservation
  | null;

export type UnitStatus = StorageUnitStatus | undefined | null;

type Devices = 'web' | 'kiosk' | 'both';

type AllocationActionsProps = {
  storageUnit: StorageUnitDtoFragment;
};

type Action = {
  id: string;
  label: string;
  onClick: () => void;
  availableOn: Devices;
  isAvailable?: boolean;
  variant: 'primary' | 'secondary' | 'danger';
  policyAction?: PolicyAction;
};

const isActionAvailable = (
  actionDevice: Devices,
  currentDevice: Devices,
  isAvailable?: boolean
): boolean => {
  return (
    (actionDevice === currentDevice || actionDevice === 'both') &&
    (isAvailable === undefined || isAvailable)
  );
};

const AllocationActions = ({ storageUnit }: AllocationActionsProps) => {
  const { t } = useTranslation();

  const deviceType = IS_KIOSK ? 'kiosk' : 'web';

  const [actionResult] = useState<string>('');
  const [openModal, setOpenModal] = useState<OpenModal>(null);
  const [showModalCloseConfirmation, setShowModalCloseConfirmation] =
    useState<boolean>(false);

  const [availableActions] = useState<Action[]>([
    {
      id: 'open',
      label: 'Page.StorageUnit.Actions.Open',
      policyAction: 'openStorageUnitWithReservation',
      onClick: () =>
        setOpenModal(
          storageUnit.allocation?.reservation?.isBlockingReservation
            ? OpenModalTypes.OpenStorageUnitWithoutPin
            : OpenModalTypes.OpenStorageUnit
        ),
      variant: 'primary',
      availableOn: 'both',
      isAvailable: deviceType === 'web' || !!storageUnit.allocation,
    },
    {
      id: 'forced-open',
      label: 'Page.StorageUnit.Actions.ForcedOpen',
      policyAction: 'forcedStorageOpen',
      onClick: () =>
        setOpenModal(OpenModalTypes.ForcedOpenStorageUnitWithReservation),
      variant: 'danger',
      availableOn: 'both',
      isAvailable: !!storageUnit.allocation,
    },
    {
      id: 'update-reservation-state',
      label: 'Page.StorageUnit.Actions.UpdateReservationState',
      policyAction: 'editReservation',
      onClick: () => setOpenModal(OpenModalTypes.UpdateReservationState),
      variant: 'primary',
      availableOn: 'both',
      isAvailable: !storageUnit.allocation?.reservation?.isBlockingReservation,
    },
    {
      id: 'change-blocking-reservation-tenant-subject-id',
      label:
        'Page.StorageUnit.Actions.ChangeBlockingReservationTenantSubjectId',
      policyAction: 'editReservation',
      onClick: () =>
        setOpenModal(OpenModalTypes.ChangeBlockingReservationTenantSubjectId),
      variant: 'primary',
      availableOn: 'both',
      isAvailable: storageUnit.allocation?.reservation?.isBlockingReservation,
    },
    {
      id: 'terminate',
      label: 'Page.StorageUnit.Actions.Terminate.Allocation',
      policyAction: 'forcedReservationTerminate',
      onClick: () => setOpenModal(OpenModalTypes.TerminateAllocation),
      variant: 'danger',
      availableOn: 'both',
      isAvailable: !!storageUnit.allocation,
    },
    {
      id: 'resend-sms',
      label: 'Page.StorageUnit.Actions.ResendSms',
      policyAction: 'editReservation',
      onClick: () => setOpenModal(OpenModalTypes.ResendSms),
      variant: 'primary',
      availableOn: 'both',
      isAvailable:
        !!storageUnit.allocation?.reservation.accessCredentials?.[0]
          ?.phoneNumber,
    },
    // TODO: uncomment when reporting will be ready
    // {
    //   id: 'block',
    //   label: 'Page.StorageUnit.Actions.Status.Block',
    //   onClick: () => setOpenModal(OpenModalTypes.UpdateStatus),
    //   variant: 'primary',
    //   availableOn: 'web',
    //   isAvailable: storageUnitStatus !== StorageUnitStatus.Broken,
    // },
    // {
    //   id: 'unblock',
    //   label: 'Page.StorageUnit.Actions.Status.Unblock',
    //   onClick: () => setOpenModal(OpenModalTypes.UpdateStatus),
    //   variant: 'primary',
    //   availableOn: 'web',
    //   isAvailable: storageUnitStatus === StorageUnitStatus.Broken,
    // },
    // {
    //   id: 'maintenance',
    //   label: 'Page.StorageUnit.Actions.Maintenance',
    //   onClick: () => setOpenModal(OpenModalTypes.RequestMaintenance),
    //   variant: 'primary',
    //   availableOn: 'web',
    //   isAvailable: !isMaintenanceRequested,
    // },
    // {
    //   id: 'redCard',
    //   label: 'Page.StorageUnit.Actions.Report.RedCard',
    //   onClick: () => alert(`Nahlásí RedCard pro: ${storageUnitId}`),
    //   variant: 'primary',
    //   availableOn: 'both',
    // },
    // {
    //   id: 'missingPackage',
    //   label: 'Page.StorageUnit.Actions.Report.MissingPackage',
    //   onClick: () => alert(`Nahlásí chybějící zásilku pro: ${storageUnitId}`),
    //   variant: 'primary',
    //   availableOn: 'both',
    // },
  ]);

  return (
    <>
      <Flex flexDirection="column" gap="3rem" mt="5rem">
        <Heading variant="h3" title={t('Page.StorageUnit.Actions.Heading')} />
        {actionResult && (
          <Notification testId="action-result" message={actionResult} />
        )}
        <Flex gap="3rem">
          {availableActions
            .filter((action) =>
              isActionAvailable(
                action.availableOn,
                deviceType,
                action.isAvailable
              )
            )
            .map((action) => {
              const buttonProps: ButtonProps = {
                testId: `action=${action.id}`,
                variant: action.variant,
                size: 'l',
                title: t(action.label),
                onClick: action.onClick,
              };

              if (action.policyAction) {
                return (
                  <PolicyGuardWrapper
                    key={action.id}
                    action={action.policyAction}
                  >
                    <Button {...buttonProps} />
                  </PolicyGuardWrapper>
                );
              }
              return <Button key={action.id} {...buttonProps} />;
            })}
        </Flex>
      </Flex>
      <Modal
        testId="unit-actions-modal"
        size="xl"
        showCloseButton={true}
        isOpen={openModal !== null}
        onClose={() => setShowModalCloseConfirmation(true)}
      >
        <ActionsModalContent
          storageUnit={storageUnit}
          contentType={openModal}
        />
      </Modal>
      <ConfirmModal
        zIndexOffset={1000}
        modalTitle={t('Page.StorageUnit.Actions.CloseAction.ConfirmTitle')}
        modalDescription={t('Page.StorageUnit.Actions.CloseAction.ConfirmText')}
        isOpen={showModalCloseConfirmation}
        confirm={{
          text: t('Page.StorageUnit.Actions.CloseAction.ConfirmButton'),
          variant: 'danger',
        }}
        cancelText={t('Page.StorageUnit.Actions.CloseAction.CancelButton')}
        onClose={() => setShowModalCloseConfirmation(false)}
        onConfirm={() => {
          setOpenModal(null);
          setShowModalCloseConfirmation(false);
        }}
      />
    </>
  );
};

export default AllocationActions;
