import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { fetcher } from 'utils/gql/fetcher';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: { input: string; output: string };
  /** The `TimeSpan` scalar represents an ISO-8601 compliant duration type. */
  TimeSpan: { input: string; output: string };
  UUID: { input: string; output: string };
};

export enum AccessCredentialAction {
  Peek = 'PEEK',
  Pickup = 'PICKUP',
  StockIn = 'STOCK_IN',
  StockOut = 'STOCK_OUT',
  Store = 'STORE',
}

export type AccessCredentialsDto = {
  __typename?: 'AccessCredentialsDto';
  accessCredentialActions: Array<AccessCredentialAction>;
  id: Scalars['UUID']['output'];
  installationId?: Maybe<Scalars['UUID']['output']>;
  personIdentifier?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  pinCode?: Maybe<Scalars['String']['output']>;
  pointId?: Maybe<Scalars['UUID']['output']>;
  projectId: Scalars['UUID']['output'];
  reservationId?: Maybe<Scalars['UUID']['output']>;
  serviceType: ServiceType;
  tenantSubjectId: Scalars['UUID']['output'];
  validFrom?: Maybe<Scalars['DateTime']['output']>;
  validTo?: Maybe<Scalars['DateTime']['output']>;
};

export type AllocationDto = {
  __typename?: 'AllocationDto';
  content: ContentDto;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  expiredAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['UUID']['output'];
  refusedAt?: Maybe<Scalars['DateTime']['output']>;
  reservation: ReservationDtoV2;
  startedAt: Scalars['DateTime']['output'];
  status: AllocationStatus;
  storageUnitId: Scalars['UUID']['output'];
  terminatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum AllocationStatus {
  Ended = 'ENDED',
  Expired = 'EXPIRED',
  Refused = 'REFUSED',
  Started = 'STARTED',
  Terminated = 'TERMINATED',
}

export type AppConfigurationDto = {
  __typename?: 'AppConfigurationDto';
  callCenterPhoneNumber: Scalars['String']['output'];
  isPointOnline: Scalars['Boolean']['output'];
  pointFullName: Scalars['String']['output'];
  projectId: Scalars['UUID']['output'];
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION',
}

export type AreaDto = {
  __typename?: 'AreaDto';
  areaTenants?: Maybe<Array<AreaTenantDto>>;
  deactivationReason?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  isActive: Scalars['Boolean']['output'];
  isDefaultSharedArea: Scalars['Boolean']['output'];
  point?: Maybe<PointDto>;
  pointId: Scalars['UUID']['output'];
  storageUnits?: Maybe<Array<StorageUnitDto>>;
  tenantSubjectIds?: Maybe<Array<Scalars['UUID']['output']>>;
};

export type AreaTenantDto = {
  __typename?: 'AreaTenantDto';
  area?: Maybe<AreaDto>;
  areaId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  tenantSubjectId: Scalars['UUID']['output'];
};

export enum BlockingReservationReason {
  BoxCannotBeClosed = 'BOX_CANNOT_BE_CLOSED',
  BoxDidNotOpen = 'BOX_DID_NOT_OPEN',
  BrokenDoor = 'BROKEN_DOOR',
  BrokenLock = 'BROKEN_LOCK',
  DirtyBox = 'DIRTY_BOX',
  MissingContent = 'MISSING_CONTENT',
  Other = 'OTHER',
  UnidentifiedContent = 'UNIDENTIFIED_CONTENT',
  WrongContent = 'WRONG_CONTENT',
}

export type CompanyInfoDto = {
  __typename?: 'CompanyInfoDto';
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  subjectId: Scalars['UUID']['output'];
};

export type CompanyTableItemDto = {
  __typename?: 'CompanyTableItemDto';
  id: Scalars['UUID']['output'];
  isProvider: Scalars['Boolean']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  logoUrlUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  subjectId: Scalars['UUID']['output'];
  teamsMembersCount: Scalars['Int']['output'];
  type: CompanyType;
};

export type CompanyTableItemDtoPaginatedListDto = {
  __typename?: 'CompanyTableItemDtoPaginatedListDto';
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  items: Array<CompanyTableItemDto>;
  pageIndex: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export enum CompanyType {
  Big = 'BIG',
  Conteg = 'CONTEG',
  DevelopmentAndTesting = 'DEVELOPMENT_AND_TESTING',
  Small = 'SMALL',
}

export type ContentDto = {
  __typename?: 'ContentDto';
  allocation?: Maybe<AllocationDto>;
  dimensions?: Maybe<DimensionsDto>;
  evictedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['UUID']['output'];
  pickedUpAt?: Maybe<Scalars['DateTime']['output']>;
  requiredStorageUnitSizeId?: Maybe<Scalars['UUID']['output']>;
  reservation?: Maybe<ReservationDtoV2>;
  reservationId: Scalars['UUID']['output'];
  status: ContentStatus;
  stockedInAt?: Maybe<Scalars['DateTime']['output']>;
  stockedOutAt?: Maybe<Scalars['DateTime']['output']>;
  storedAt?: Maybe<Scalars['DateTime']['output']>;
  trackingIdentifiers: Array<TrackingIdentifierDto>;
};

export enum ContentStatus {
  CancelledNoSuitableBox = 'CANCELLED_NO_SUITABLE_BOX',
  CancelledOversized = 'CANCELLED_OVERSIZED',
  Evicted = 'EVICTED',
  PickedUp = 'PICKED_UP',
  StockedIn = 'STOCKED_IN',
  StockedOut = 'STOCKED_OUT',
  Stored = 'STORED',
  WaitingForAllocation = 'WAITING_FOR_ALLOCATION',
}

export type CoolingDto = {
  __typename?: 'CoolingDto';
  id: Scalars['UUID']['output'];
};

export type CreateBlockingReservationRequestInput = {
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  pointId: Scalars['UUID']['input'];
  projectId: Scalars['UUID']['input'];
  reason: BlockingReservationReason;
  reasonDescription?: InputMaybe<Scalars['String']['input']>;
  serviceType: ServiceTypeEnum;
  storageUnitId: Scalars['UUID']['input'];
  tenantSubjectId: Scalars['UUID']['input'];
};

export type CreateDeliveryReservationOutputDto = {
  __typename?: 'CreateDeliveryReservationOutputDto';
  pinCode: Scalars['String']['output'];
  reservationId: Scalars['UUID']['output'];
};

export type CreateDeliveryReservationRequestInput = {
  contentDepth: Scalars['Int']['input'];
  contentHeight: Scalars['Int']['input'];
  contentWidth: Scalars['Int']['input'];
  duration: Scalars['String']['input'];
  expiration: Scalars['DateTime']['input'];
  from: Scalars['DateTime']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  pinCode?: InputMaybe<Scalars['String']['input']>;
  pointId: Scalars['UUID']['input'];
  projectId: Scalars['UUID']['input'];
  tenantSubjectId: Scalars['UUID']['input'];
  trackingIdentifier: Scalars['String']['input'];
};

export enum Currency {
  Czk = 'CZK',
  Default = 'DEFAULT',
  Eur = 'EUR',
  Gbp = 'GBP',
  Ron = 'RON',
  Rub = 'RUB',
  Usd = 'USD',
}

export type DeviceDto = {
  __typename?: 'DeviceDto';
  cooling?: Maybe<CoolingDto>;
  deviceModelNumber?: Maybe<Scalars['String']['output']>;
  deviceType: DeviceType;
  id: Scalars['UUID']['output'];
  installation?: Maybe<InstallationDto>;
  installationId?: Maybe<Scalars['UUID']['output']>;
  kioskPc?: Maybe<KioskPcDto>;
  lock?: Maybe<LockDto>;
  lockController?: Maybe<LockControllerDto>;
  lockNetworkUnit?: Maybe<LockNetworkUnitDto>;
  note?: Maybe<Scalars['String']['output']>;
  paymentTerminal?: Maybe<PaymentTerminalDto>;
  pointId?: Maybe<Scalars['UUID']['output']>;
  printer?: Maybe<PrinterDto>;
  relay?: Maybe<RelayDto>;
  router?: Maybe<RouterDto>;
  touchDisplay?: Maybe<TouchDisplayDto>;
};

export enum DeviceType {
  Cooling = 'COOLING',
  KioskPc = 'KIOSK_PC',
  Lock = 'LOCK',
  LockController = 'LOCK_CONTROLLER',
  LockNetworkUnit = 'LOCK_NETWORK_UNIT',
  PaymentTerminal = 'PAYMENT_TERMINAL',
  Printer = 'PRINTER',
  Relay = 'RELAY',
  Router = 'ROUTER',
  TouchDisplay = 'TOUCH_DISPLAY',
}

export type DimensionsDto = {
  __typename?: 'DimensionsDto';
  depth: Scalars['Int']['output'];
  height: Scalars['Int']['output'];
  preserveVerticalOrientation: Scalars['Boolean']['output'];
  width: Scalars['Int']['output'];
};

export enum DisplayRotationDirection {
  Left = 'LEFT',
  Right = 'RIGHT',
}

export type EndUserAccessPinInputDtoInput = {
  accessPin: Scalars['String']['input'];
};

export type GqlStorageUnitDto = {
  __typename?: 'GqlStorageUnitDto';
  allocation?: Maybe<AllocationDto>;
  company?: Maybe<CompanyInfoDto>;
  hasReservation: Scalars['Boolean']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  reservation?: Maybe<ReservationDtoV2>;
  storageUnit: StorageUnitDto;
};

export type GqlStorageUnitListDto = {
  __typename?: 'GqlStorageUnitListDto';
  allocationFrom?: Maybe<Scalars['DateTime']['output']>;
  allocationTo?: Maybe<Scalars['DateTime']['output']>;
  isDeliverySubmission: Scalars['Boolean']['output'];
  isEmpty: Scalars['Boolean']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  requiredStorageUnitFeatures: Array<StorageUnitFeature>;
  storageUnit: StorageUnitDto;
  trackingIdentifier?: Maybe<Scalars['String']['output']>;
};

export type GqlVerifyAccessPinDto = {
  __typename?: 'GqlVerifyAccessPinDto';
  bindingFee?: Maybe<Scalars['Float']['output']>;
};

export type InstallationDto = {
  __typename?: 'InstallationDto';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  deactivationReason?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  geoRegion?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  isActive: Scalars['Boolean']['output'];
  isArchived: Scalars['Boolean']['output'];
  isIndoor: Scalars['Boolean']['output'];
  isParkingPossible: Scalars['Boolean']['output'];
  isVisuallyImpairedAccessible: Scalars['Boolean']['output'];
  isWheelchairAccessible: Scalars['Boolean']['output'];
  latitude?: Maybe<Scalars['Float']['output']>;
  locationDescription?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  openingHourExceptionsSummary?: Maybe<Scalars['String']['output']>;
  operatingHours?: Maybe<Array<OperatingHoursDto>>;
  ownerSubjectId: Scalars['UUID']['output'];
  partOfCity?: Maybe<Scalars['String']['output']>;
  points?: Maybe<Array<PointDto>>;
  postalCode?: Maybe<Scalars['String']['output']>;
  projectId: Scalars['UUID']['output'];
  providerSubjectId: Scalars['UUID']['output'];
  region?: Maybe<Scalars['String']['output']>;
  streetName?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  streetOrientationNumber?: Maybe<Scalars['String']['output']>;
  timeZone?: Maybe<Scalars['String']['output']>;
};

export type KioskPcDto = {
  __typename?: 'KioskPcDto';
  ipAddress?: Maybe<Scalars['String']['output']>;
};

export enum Language {
  Cs = 'CS',
  De = 'DE',
  En = 'EN',
  Es = 'ES',
  Fr = 'FR',
  Sk = 'SK',
  Uk = 'UK',
}

export type LockControllerDto = {
  __typename?: 'LockControllerDto';
  description?: Maybe<Scalars['String']['output']>;
};

export type LockDto = {
  __typename?: 'LockDto';
  fallbackLockOpenAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['UUID']['output'];
  lastLockOpenRequestedAt?: Maybe<Scalars['DateTime']['output']>;
  lastStatus: LockStatus;
  lockIdentifier?: Maybe<Scalars['String']['output']>;
  storageUnit?: Maybe<StorageUnitDto>;
  storageUnitId: Scalars['UUID']['output'];
};

export type LockNetworkUnitDto = {
  __typename?: 'LockNetworkUnitDto';
  ipAddress?: Maybe<Scalars['String']['output']>;
  port: Scalars['Int']['output'];
};

export enum LockStatus {
  Closed = 'CLOSED',
  Failed = 'FAILED',
  Opened = 'OPENED',
}

export type Mutation = {
  __typename?: 'Mutation';
  changeBlockingReservationTenantSubjectId: Scalars['Boolean']['output'];
  changeStorageUnitStatus: StorageUnitDto;
  createBlockingReservationRequestV2: Scalars['Boolean']['output'];
  createDeliveryReservation: CreateDeliveryReservationOutputDto;
  forcedOpenStorageUnitWithReservation: Scalars['Boolean']['output'];
  generateStorageUnitOneTimePassword: OneTimePasswordDto;
  openEmptyStorageUnit: Scalars['Boolean']['output'];
  openEmptyStorageUnits: Scalars['Boolean']['output'];
  openServiceModule: Scalars['Boolean']['output'];
  openStorageUnit: OpenStorageUnitDto;
  requestMaintenanceStorageUnit: Scalars['UUID']['output'];
  resendSms: Scalars['Boolean']['output'];
  setStorageUnitStatus: StorageUnitDto;
  terminateAllocationV2: Scalars['Boolean']['output'];
  updateContentState: Scalars['Boolean']['output'];
  updateReservationDuration: Scalars['Boolean']['output'];
  verifyAccessPin: GqlVerifyAccessPinDto;
};

export type MutationChangeBlockingReservationTenantSubjectIdArgs = {
  reservationId: Scalars['UUID']['input'];
  tenantSubjectId: Scalars['UUID']['input'];
};

export type MutationChangeStorageUnitStatusArgs = {
  status: StorageUnitStatus;
  statusChangeReason: Scalars['String']['input'];
  storageUnitId: Scalars['UUID']['input'];
};

export type MutationCreateBlockingReservationRequestV2Args = {
  input: CreateBlockingReservationRequestInput;
};

export type MutationCreateDeliveryReservationArgs = {
  request: CreateDeliveryReservationRequestInput;
};

export type MutationForcedOpenStorageUnitWithReservationArgs = {
  pointId: Scalars['UUID']['input'];
  storageUnitId: Scalars['UUID']['input'];
};

export type MutationGenerateStorageUnitOneTimePasswordArgs = {
  pointId: Scalars['UUID']['input'];
  storageUnitId: Scalars['UUID']['input'];
};

export type MutationOpenEmptyStorageUnitArgs = {
  pointId: Scalars['UUID']['input'];
  storageUnitId: Scalars['UUID']['input'];
};

export type MutationOpenEmptyStorageUnitsArgs = {
  pointId: Scalars['UUID']['input'];
  storageUnitIds: Array<Scalars['UUID']['input']>;
};

export type MutationOpenServiceModuleArgs = {
  pointId: Scalars['UUID']['input'];
  storageUnitId: Scalars['UUID']['input'];
};

export type MutationOpenStorageUnitArgs = {
  endUser: EndUserAccessPinInputDtoInput;
  isBlockingReservation: Scalars['Boolean']['input'];
  pointId: Scalars['UUID']['input'];
  projectId: Scalars['UUID']['input'];
  serviceType: ServiceTypeEnum;
  singleTenantVerification: Scalars['Boolean']['input'];
  storageUnitId?: InputMaybe<Scalars['UUID']['input']>;
  tenantSubjectId?: InputMaybe<Scalars['UUID']['input']>;
};

export type MutationRequestMaintenanceStorageUnitArgs = {
  pointId: Scalars['UUID']['input'];
  statusChangeReason: Scalars['String']['input'];
  storageUnitId: Scalars['UUID']['input'];
};

export type MutationResendSmsArgs = {
  pointId: Scalars['UUID']['input'];
  reservationId: Scalars['UUID']['input'];
};

export type MutationSetStorageUnitStatusArgs = {
  setStorageUnitStatusDto?: InputMaybe<PartialUpdateStorageUnitDtoInput>;
  storageUnitId: Scalars['UUID']['input'];
};

export type MutationTerminateAllocationV2Args = {
  storageUnitId: Scalars['UUID']['input'];
};

export type MutationUpdateContentStateArgs = {
  contentId?: InputMaybe<Scalars['UUID']['input']>;
  isPickedUpBySender?: InputMaybe<Scalars['Boolean']['input']>;
  pointId: Scalars['UUID']['input'];
  state: UpdateContentStateEnum;
  storageUnitId?: InputMaybe<Scalars['UUID']['input']>;
  tenantSubjectId: Scalars['UUID']['input'];
  trackingIdentifier?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['UUID']['input']>;
};

export type MutationUpdateReservationDurationArgs = {
  reservationId: Scalars['UUID']['input'];
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MutationVerifyAccessPinArgs = {
  accessPin: Scalars['String']['input'];
  pointId: Scalars['UUID']['input'];
  projectId: Scalars['UUID']['input'];
  serviceType: ServiceTypeEnum;
  storageUnitId: Scalars['UUID']['input'];
  tenantSubjectId: Scalars['UUID']['input'];
};

export type OneTimePasswordDto = {
  __typename?: 'OneTimePasswordDto';
  oneTimePassword?: Maybe<Scalars['String']['output']>;
  validTo: Scalars['DateTime']['output'];
};

export type OpenStorageUnitDto = {
  __typename?: 'OpenStorageUnitDto';
  bindingFee?: Maybe<Scalars['Float']['output']>;
  storageUnitId: Scalars['UUID']['output'];
};

export type OperatingHoursDto = {
  __typename?: 'OperatingHoursDto';
  dayOfWeek: Scalars['Int']['output'];
  endTime?: Maybe<Scalars['TimeSpan']['output']>;
  id: Scalars['UUID']['output'];
  installation?: Maybe<InstallationDto>;
  installationId: Scalars['UUID']['output'];
  isAllDayClosed: Scalars['Boolean']['output'];
  isUnexpected: Scalars['Boolean']['output'];
  startTime?: Maybe<Scalars['TimeSpan']['output']>;
  unexpectedDateTime?: Maybe<Scalars['DateTime']['output']>;
};

export type PartialUpdateStorageUnitDtoInput = {
  areaId?: InputMaybe<Scalars['UUID']['input']>;
  features: Array<StorageUnitFeature>;
  statusChangeReason?: InputMaybe<Scalars['String']['input']>;
  storageUnitStatus?: InputMaybe<StorageUnitStatus>;
};

export type PaymentTerminalDto = {
  __typename?: 'PaymentTerminalDto';
  currency: Currency;
  deviceId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  lastLifeCheck?: Maybe<Scalars['DateTime']['output']>;
  pid?: Maybe<Scalars['String']['output']>;
  serialNumber?: Maybe<Scalars['String']['output']>;
};

export type Point = {
  __typename?: 'Point';
  id: Scalars['UUID']['output'];
  installationId: Scalars['UUID']['output'];
  isActive: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type PointDto = {
  __typename?: 'PointDto';
  areas?: Maybe<Array<AreaDto>>;
  deactivationReason?: Maybe<Scalars['String']['output']>;
  devices?: Maybe<Array<DeviceDto>>;
  id: Scalars['UUID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  installation?: Maybe<InstallationDto>;
  installationId: Scalars['UUID']['output'];
  internetSpeedDownloadBytes?: Maybe<Scalars['Float']['output']>;
  internetSpeedMeasuredAt?: Maybe<Scalars['DateTime']['output']>;
  internetSpeedUploadBytes?: Maybe<Scalars['Float']['output']>;
  isActive: Scalars['Boolean']['output'];
  isConfigured: Scalars['Boolean']['output'];
  isManualLayoutManagement: Scalars['Boolean']['output'];
  isOnline?: Maybe<Scalars['Boolean']['output']>;
  isOpenForEdit: Scalars['Boolean']['output'];
  lastOnlineAt?: Maybe<Scalars['DateTime']['output']>;
  localApplicationVersion?: Maybe<Scalars['String']['output']>;
  pointIdentifier?: Maybe<Scalars['String']['output']>;
  pointPrefix?: Maybe<Scalars['String']['output']>;
  pointRegistrationNumber?: Maybe<Scalars['String']['output']>;
  pointSequenceNumber: Scalars['Int']['output'];
  storageColumns?: Maybe<Array<StorageColumnDto>>;
};

export type PrinterDto = {
  __typename?: 'PrinterDto';
  device?: Maybe<DeviceDto>;
  deviceId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  status?: Maybe<Array<PrinterStatus>>;
};

export enum PrinterStatus {
  ConnectionNoBixolonPrinter = 'CONNECTION_NO_BIXOLON_PRINTER',
  ConnectionNoConnectedPrinter = 'CONNECTION_NO_CONNECTED_PRINTER',
  ConnectionPortOpenError = 'CONNECTION_PORT_OPEN_ERROR',
  CoverOpen = 'COVER_OPEN',
  CutterError = 'CUTTER_ERROR',
  Ok = 'OK',
  Overheatting = 'OVERHEATTING',
  PaperEmpty = 'PAPER_EMPTY',
  PaperIn = 'PAPER_IN',
  PaperJam = 'PAPER_JAM',
  PaperNearEnd = 'PAPER_NEAR_END',
  PaperToBeTaken = 'PAPER_TO_BE_TAKEN',
  Unknown = 'UNKNOWN',
  UnrecoverableError = 'UNRECOVERABLE_ERROR',
}

export type Query = {
  __typename?: 'Query';
  appConfig: AppConfigurationDto;
  companies: CompanyTableItemDtoPaginatedListDto;
  points: Array<Maybe<PointDto>>;
  storageUnit: GqlStorageUnitDto;
  storageUnitSizes: Array<StorageUnitSizeDto>;
  storageUnits: Array<Maybe<GqlStorageUnitListDto>>;
  tenants: Array<Maybe<TenantDto>>;
  tenantsByProject: Array<Maybe<TenantByProjectDto>>;
  userRoles?: Maybe<Array<UserRoleDto>>;
  userTypes?: Maybe<Array<Scalars['String']['output']>>;
};

export type QueryAppConfigArgs = {
  pointId: Scalars['UUID']['input'];
};

export type QueryCompaniesArgs = {
  allInOnePage?: InputMaybe<Scalars['Boolean']['input']>;
  businessTypeId?: InputMaybe<Scalars['ID']['input']>;
  callCenterTeamId?: InputMaybe<Scalars['UUID']['input']>;
  pageIndex?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  pointId?: InputMaybe<Scalars['UUID']['input']>;
};

export type QueryPointsArgs = {
  installationId?: InputMaybe<Scalars['UUID']['input']>;
};

export type QueryStorageUnitArgs = {
  pointId: Scalars['UUID']['input'];
  storageUnitId: Scalars['UUID']['input'];
};

export type QueryStorageUnitsArgs = {
  pointId: Scalars['UUID']['input'];
};

export type QueryTenantsArgs = {
  pointId: Scalars['UUID']['input'];
  serviceType?: InputMaybe<ServiceTypeEnum>;
};

export type QueryTenantsByProjectArgs = {
  projectId: Scalars['UUID']['input'];
};

export type QueryUserRolesArgs = {
  userId: Scalars['UUID']['input'];
};

export type RelayDto = {
  __typename?: 'RelayDto';
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type ReservationDtoV2 = {
  __typename?: 'ReservationDtoV2';
  accessCredentials?: Maybe<Array<AccessCredentialsDto>>;
  allocations?: Maybe<Array<AllocationDto>>;
  blockingReservationReason?: Maybe<BlockingReservationReason>;
  blockingReservationReasonDescription?: Maybe<Scalars['String']['output']>;
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  contents?: Maybe<Array<ContentDto>>;
  createdAt: Scalars['DateTime']['output'];
  designatedStorageUnitId?: Maybe<Scalars['UUID']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  expiration: Scalars['DateTime']['output'];
  expiredAt?: Maybe<Scalars['DateTime']['output']>;
  finishedAt?: Maybe<Scalars['DateTime']['output']>;
  from: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  isArchived: Scalars['Boolean']['output'];
  isBlockingReservation: Scalars['Boolean']['output'];
  isDeliverySubmission: Scalars['Boolean']['output'];
  languageCode?: Maybe<Language>;
  pickupDue?: Maybe<Scalars['DateTime']['output']>;
  projectId: Scalars['UUID']['output'];
  requiredStorageUnitFeatures: Array<StorageUnitFeature>;
  serviceType: ServiceType;
  status: ReservationStatus;
  tenantSubjectId: Scalars['UUID']['output'];
  terminatedAt?: Maybe<Scalars['DateTime']['output']>;
  to: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum ReservationStatus {
  Cancelled = 'CANCELLED',
  Created = 'CREATED',
  Ended = 'ENDED',
  Expired = 'EXPIRED',
  Started = 'STARTED',
}

export type RouterDto = {
  __typename?: 'RouterDto';
  localIpAddress?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  vpnIpAddress?: Maybe<Scalars['String']['output']>;
};

export enum ServiceType {
  BagToBox = 'BAG_TO_BOX',
  Delivery = 'DELIVERY',
  HandToHand = 'HAND_TO_HAND',
}

export enum ServiceTypeEnum {
  BagToBox = 'BAG_TO_BOX',
  Delivery = 'DELIVERY',
  HandToHand = 'HAND_TO_HAND',
}

export type StorageColumnDto = {
  __typename?: 'StorageColumnDto';
  columnNumber: Scalars['Int']['output'];
  depth: Scalars['Int']['output'];
  id: Scalars['UUID']['output'];
  isActive: Scalars['Boolean']['output'];
  maxHorizontalUnits: Scalars['Int']['output'];
  maxVerticalUnits: Scalars['Int']['output'];
  point?: Maybe<PointDto>;
  pointId: Scalars['UUID']['output'];
  storageUnits?: Maybe<Array<StorageUnitDto>>;
};

export type StorageUnitDto = {
  __typename?: 'StorageUnitDto';
  area?: Maybe<AreaDto>;
  areaId: Scalars['UUID']['output'];
  features: Array<StorageUnitFeature>;
  id: Scalars['UUID']['output'];
  isLayoutManaged: Scalars['Boolean']['output'];
  lastLockStatus?: Maybe<LockStatus>;
  lock?: Maybe<LockDto>;
  name?: Maybe<Scalars['String']['output']>;
  startingHorizontalCoordinate: Scalars['Int']['output'];
  startingVerticalCoordinate: Scalars['Int']['output'];
  statusChangeReason?: Maybe<Scalars['String']['output']>;
  storageColumn?: Maybe<StorageColumnDto>;
  storageColumnId: Scalars['UUID']['output'];
  storageUnitSize?: Maybe<StorageUnitSizeDto>;
  storageUnitSizeId: Scalars['UUID']['output'];
  storageUnitStatus: StorageUnitStatus;
};

export enum StorageUnitFeature {
  AcSocket = 'AC_SOCKET',
  Cooling = 'COOLING',
}

export type StorageUnitSizeDto = {
  __typename?: 'StorageUnitSizeDto';
  friendlyName?: Maybe<Scalars['String']['output']>;
  horizontalUnits: Scalars['Int']['output'];
  id: Scalars['UUID']['output'];
  innerDepth: Scalars['Int']['output'];
  innerHeight: Scalars['Int']['output'];
  innerWidth: Scalars['Int']['output'];
  isServiceModule: Scalars['Boolean']['output'];
  label?: Maybe<Scalars['String']['output']>;
  outerDepth: Scalars['Int']['output'];
  outerHeight: Scalars['Int']['output'];
  outerWidth: Scalars['Int']['output'];
  storageUnits?: Maybe<Array<StorageUnitDto>>;
  verticalUnits: Scalars['Int']['output'];
};

export enum StorageUnitStatus {
  Blocked = 'BLOCKED',
  Broken = 'BROKEN',
  Usable = 'USABLE',
}

export type StorageUnitSubscriptionDto = {
  __typename?: 'StorageUnitSubscriptionDto';
  id: Scalars['UUID']['output'];
  label: Scalars['String']['output'];
  state: LockStatus;
};

export type Subscription = {
  __typename?: 'Subscription';
  pointStateChanged: Point;
  storageUnitStateChanged?: Maybe<StorageUnitSubscriptionDto>;
};

export type SubscriptionStorageUnitStateChangedArgs = {
  pointId: Scalars['UUID']['input'];
};

export type TenantByProjectDto = {
  __typename?: 'TenantByProjectDto';
  isProjectOwner: Scalars['Boolean']['output'];
  maxPeriod: Scalars['String']['output'];
  projectId: Scalars['UUID']['output'];
  tenantName?: Maybe<Scalars['String']['output']>;
  tenantServiceType: ServiceTypeEnum;
  tenantSubjectId: Scalars['UUID']['output'];
};

export type TenantDto = {
  __typename?: 'TenantDto';
  maxPeriod: Scalars['String']['output'];
  projectId: Scalars['UUID']['output'];
  tenantName?: Maybe<Scalars['String']['output']>;
  tenantServiceType: ServiceTypeEnum;
  tenantSubjectId: Scalars['UUID']['output'];
};

export type TouchDisplayDto = {
  __typename?: 'TouchDisplayDto';
  displayRotation: DisplayRotationDirection;
  screenHeight?: Maybe<Scalars['Int']['output']>;
  screenWidth?: Maybe<Scalars['Int']['output']>;
  touchLayerRotation: DisplayRotationDirection;
};

export type TrackingIdentifierDto = {
  __typename?: 'TrackingIdentifierDto';
  identifier: Scalars['String']['output'];
};

export enum UpdateContentStateEnum {
  PickUp = 'PICK_UP',
  StockOut = 'STOCK_OUT',
}

export type UserRoleDto = {
  __typename?: 'UserRoleDto';
  roleId: Scalars['UUID']['output'];
  userId: Scalars['UUID']['output'];
};

export type AllocationDtoFragment = {
  __typename?: 'AllocationDto';
  id: string;
  storageUnitId: string;
  status: AllocationStatus;
  content: {
    __typename?: 'ContentDto';
    id: string;
    storedAt?: string | null;
    trackingIdentifiers: Array<{
      __typename?: 'TrackingIdentifierDto';
      identifier: string;
    }>;
  };
  reservation: {
    __typename?: 'ReservationDtoV2';
    id: string;
    tenantSubjectId: string;
    isBlockingReservation: boolean;
    isDeliverySubmission: boolean;
    blockingReservationReasonDescription?: string | null;
    blockingReservationReason?: BlockingReservationReason | null;
    serviceType: ServiceType;
    languageCode?: Language | null;
    status: ReservationStatus;
    pickupDue?: string | null;
    createdAt: string;
    cancelledAt?: string | null;
    duration?: string | null;
    expiration: string;
    updatedAt: string;
    expiredAt?: string | null;
    terminatedAt?: string | null;
    finishedAt?: string | null;
    from: string;
    to: string;
    requiredStorageUnitFeatures: Array<StorageUnitFeature>;
    accessCredentials?: Array<{
      __typename?: 'AccessCredentialsDto';
      id: string;
      phoneNumber?: string | null;
    }> | null;
  };
};

export type StorageUnitListFragment = {
  __typename?: 'GqlStorageUnitListDto';
  isEmpty: boolean;
  allocationFrom?: string | null;
  allocationTo?: string | null;
  trackingIdentifier?: string | null;
  phoneNumber?: string | null;
  requiredStorageUnitFeatures: Array<StorageUnitFeature>;
  storageUnit: {
    __typename?: 'StorageUnitDto';
    id: string;
    name?: string | null;
    storageUnitStatus: StorageUnitStatus;
    features: Array<StorageUnitFeature>;
    lock?: { __typename?: 'LockDto'; lastStatus: LockStatus } | null;
    storageUnitSize?: {
      __typename?: 'StorageUnitSizeDto';
      isServiceModule: boolean;
    } | null;
  };
};

export type StorageUnitSizeFragment = {
  __typename?: 'StorageUnitSizeDto';
  isServiceModule: boolean;
  innerWidth: number;
  innerHeight: number;
  innerDepth: number;
};

export type StorageUnitDtoFragment = {
  __typename?: 'GqlStorageUnitDto';
  hasReservation: boolean;
  phoneNumber?: string | null;
  allocation?: {
    __typename?: 'AllocationDto';
    id: string;
    storageUnitId: string;
    status: AllocationStatus;
    content: {
      __typename?: 'ContentDto';
      id: string;
      storedAt?: string | null;
      trackingIdentifiers: Array<{
        __typename?: 'TrackingIdentifierDto';
        identifier: string;
      }>;
    };
    reservation: {
      __typename?: 'ReservationDtoV2';
      id: string;
      tenantSubjectId: string;
      isBlockingReservation: boolean;
      isDeliverySubmission: boolean;
      blockingReservationReasonDescription?: string | null;
      blockingReservationReason?: BlockingReservationReason | null;
      serviceType: ServiceType;
      languageCode?: Language | null;
      status: ReservationStatus;
      pickupDue?: string | null;
      createdAt: string;
      cancelledAt?: string | null;
      duration?: string | null;
      expiration: string;
      updatedAt: string;
      expiredAt?: string | null;
      terminatedAt?: string | null;
      finishedAt?: string | null;
      from: string;
      to: string;
      requiredStorageUnitFeatures: Array<StorageUnitFeature>;
      accessCredentials?: Array<{
        __typename?: 'AccessCredentialsDto';
        id: string;
        phoneNumber?: string | null;
      }> | null;
    };
  } | null;
  storageUnit: {
    __typename?: 'StorageUnitDto';
    id: string;
    name?: string | null;
    storageUnitStatus: StorageUnitStatus;
    features: Array<StorageUnitFeature>;
    lock?: { __typename?: 'LockDto'; lastStatus: LockStatus } | null;
    storageUnitSize?: {
      __typename?: 'StorageUnitSizeDto';
      isServiceModule: boolean;
      innerWidth: number;
      innerHeight: number;
      innerDepth: number;
    } | null;
  };
  company?: { __typename?: 'CompanyInfoDto'; name: string } | null;
};

export type ChangeBlockingReservationTenantSubjectIdMutationVariables = Exact<{
  reservationId: Scalars['UUID']['input'];
  tenantSubjectId: Scalars['UUID']['input'];
}>;

export type ChangeBlockingReservationTenantSubjectIdMutation = {
  __typename?: 'Mutation';
  changeBlockingReservationTenantSubjectId: boolean;
};

export type ChangeStorageUnitStatusMutationVariables = Exact<{
  storageUnitId: Scalars['UUID']['input'];
  status: StorageUnitStatus;
  statusChangeReason: Scalars['String']['input'];
}>;

export type ChangeStorageUnitStatusMutation = {
  __typename?: 'Mutation';
  changeStorageUnitStatus: {
    __typename?: 'StorageUnitDto';
    id: string;
    storageUnitStatus: StorageUnitStatus;
  };
};

export type CreateBlockingReservationRequestV2MutationVariables = Exact<{
  input: CreateBlockingReservationRequestInput;
}>;

export type CreateBlockingReservationRequestV2Mutation = {
  __typename?: 'Mutation';
  createBlockingReservationRequestV2: boolean;
};

export type CreateDeliveryReservationMutationVariables = Exact<{
  request: CreateDeliveryReservationRequestInput;
}>;

export type CreateDeliveryReservationMutation = {
  __typename?: 'Mutation';
  createDeliveryReservation: {
    __typename?: 'CreateDeliveryReservationOutputDto';
    pinCode: string;
  };
};

export type ForcedOpenStorageUnitWithReservationMutationVariables = Exact<{
  pointId: Scalars['UUID']['input'];
  storageUnitId: Scalars['UUID']['input'];
}>;

export type ForcedOpenStorageUnitWithReservationMutation = {
  __typename?: 'Mutation';
  forcedOpenStorageUnitWithReservation: boolean;
};

export type GenerateStorageUnitOneTimePasswordMutationVariables = Exact<{
  pointId: Scalars['UUID']['input'];
  storageUnitId: Scalars['UUID']['input'];
}>;

export type GenerateStorageUnitOneTimePasswordMutation = {
  __typename?: 'Mutation';
  generateStorageUnitOneTimePassword: {
    __typename?: 'OneTimePasswordDto';
    oneTimePassword?: string | null;
    validTo: string;
  };
};

export type OpenEmptyStorageUnitMutationVariables = Exact<{
  pointId: Scalars['UUID']['input'];
  storageUnitId: Scalars['UUID']['input'];
}>;

export type OpenEmptyStorageUnitMutation = {
  __typename?: 'Mutation';
  openEmptyStorageUnit: boolean;
};

export type OpenEmptyStorageUnitsMutationVariables = Exact<{
  pointId: Scalars['UUID']['input'];
  storageUnitIds: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
}>;

export type OpenEmptyStorageUnitsMutation = {
  __typename?: 'Mutation';
  openEmptyStorageUnits: boolean;
};

export type OpenServiceModuleMutationVariables = Exact<{
  pointId: Scalars['UUID']['input'];
  storageUnitId: Scalars['UUID']['input'];
}>;

export type OpenServiceModuleMutation = {
  __typename?: 'Mutation';
  openServiceModule: boolean;
};

export type OpenStorageUnitMutationVariables = Exact<{
  pointId: Scalars['UUID']['input'];
  endUser: EndUserAccessPinInputDtoInput;
  isBlockingReservation: Scalars['Boolean']['input'];
  storageUnitId?: InputMaybe<Scalars['UUID']['input']>;
  projectId: Scalars['UUID']['input'];
  serviceType: ServiceTypeEnum;
  tenantSubjectId?: InputMaybe<Scalars['UUID']['input']>;
  singleTenantVerification: Scalars['Boolean']['input'];
}>;

export type OpenStorageUnitMutation = {
  __typename?: 'Mutation';
  openStorageUnit: {
    __typename?: 'OpenStorageUnitDto';
    bindingFee?: number | null;
    storageUnitId: string;
  };
};

export type RequestMaintenanceStorageUnitMutationVariables = Exact<{
  pointId: Scalars['UUID']['input'];
  storageUnitId: Scalars['UUID']['input'];
  statusChangeReason: Scalars['String']['input'];
}>;

export type RequestMaintenanceStorageUnitMutation = {
  __typename?: 'Mutation';
  requestMaintenanceStorageUnit: string;
};

export type ResendSmsMutationVariables = Exact<{
  pointId: Scalars['UUID']['input'];
  reservationId: Scalars['UUID']['input'];
}>;

export type ResendSmsMutation = { __typename?: 'Mutation'; resendSms: boolean };

export type TerminateAllocationV2MutationVariables = Exact<{
  storageUnitId: Scalars['UUID']['input'];
}>;

export type TerminateAllocationV2Mutation = {
  __typename?: 'Mutation';
  terminateAllocationV2: boolean;
};

export type UpdateContentStateMutationVariables = Exact<{
  pointId: Scalars['UUID']['input'];
  tenantSubjectId: Scalars['UUID']['input'];
  trackingIdentifier?: InputMaybe<Scalars['String']['input']>;
  state: UpdateContentStateEnum;
  contentId?: InputMaybe<Scalars['UUID']['input']>;
  storageUnitId?: InputMaybe<Scalars['UUID']['input']>;
  userId?: InputMaybe<Scalars['UUID']['input']>;
  isPickedUpBySender?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type UpdateContentStateMutation = {
  __typename?: 'Mutation';
  updateContentState: boolean;
};

export type UpdateReservationDurationMutationVariables = Exact<{
  reservationId: Scalars['UUID']['input'];
  to: Scalars['DateTime']['input'];
}>;

export type UpdateReservationDurationMutation = {
  __typename?: 'Mutation';
  updateReservationDuration: boolean;
};

export type VerifyAccessPinMutationVariables = Exact<{
  pointId: Scalars['UUID']['input'];
  storageUnitId: Scalars['UUID']['input'];
  accessPin: Scalars['String']['input'];
  projectId: Scalars['UUID']['input'];
  serviceType: ServiceTypeEnum;
  tenantSubjectId: Scalars['UUID']['input'];
}>;

export type VerifyAccessPinMutation = {
  __typename?: 'Mutation';
  verifyAccessPin: {
    __typename?: 'GqlVerifyAccessPinDto';
    bindingFee?: number | null;
  };
};

export type AppConfigQueryVariables = Exact<{
  pointId: Scalars['UUID']['input'];
}>;

export type AppConfigQuery = {
  __typename?: 'Query';
  appConfig: {
    __typename?: 'AppConfigurationDto';
    isPointOnline: boolean;
    callCenterPhoneNumber: string;
    pointFullName: string;
    projectId: string;
  };
};

export type StorageUnitSizesQueryVariables = Exact<{ [key: string]: never }>;

export type StorageUnitSizesQuery = {
  __typename?: 'Query';
  storageUnitSizes: Array<{
    __typename?: 'StorageUnitSizeDto';
    isServiceModule: boolean;
    innerWidth: number;
    innerHeight: number;
    innerDepth: number;
  }>;
};

export type StorageUnitQueryVariables = Exact<{
  pointId: Scalars['UUID']['input'];
  storageUnitId: Scalars['UUID']['input'];
}>;

export type StorageUnitQuery = {
  __typename?: 'Query';
  storageUnit: {
    __typename?: 'GqlStorageUnitDto';
    hasReservation: boolean;
    phoneNumber?: string | null;
    allocation?: {
      __typename?: 'AllocationDto';
      id: string;
      storageUnitId: string;
      status: AllocationStatus;
      content: {
        __typename?: 'ContentDto';
        id: string;
        storedAt?: string | null;
        trackingIdentifiers: Array<{
          __typename?: 'TrackingIdentifierDto';
          identifier: string;
        }>;
      };
      reservation: {
        __typename?: 'ReservationDtoV2';
        id: string;
        tenantSubjectId: string;
        isBlockingReservation: boolean;
        isDeliverySubmission: boolean;
        blockingReservationReasonDescription?: string | null;
        blockingReservationReason?: BlockingReservationReason | null;
        serviceType: ServiceType;
        languageCode?: Language | null;
        status: ReservationStatus;
        pickupDue?: string | null;
        createdAt: string;
        cancelledAt?: string | null;
        duration?: string | null;
        expiration: string;
        updatedAt: string;
        expiredAt?: string | null;
        terminatedAt?: string | null;
        finishedAt?: string | null;
        from: string;
        to: string;
        requiredStorageUnitFeatures: Array<StorageUnitFeature>;
        accessCredentials?: Array<{
          __typename?: 'AccessCredentialsDto';
          id: string;
          phoneNumber?: string | null;
        }> | null;
      };
    } | null;
    storageUnit: {
      __typename?: 'StorageUnitDto';
      id: string;
      name?: string | null;
      storageUnitStatus: StorageUnitStatus;
      features: Array<StorageUnitFeature>;
      lock?: { __typename?: 'LockDto'; lastStatus: LockStatus } | null;
      storageUnitSize?: {
        __typename?: 'StorageUnitSizeDto';
        isServiceModule: boolean;
        innerWidth: number;
        innerHeight: number;
        innerDepth: number;
      } | null;
    };
    company?: { __typename?: 'CompanyInfoDto'; name: string } | null;
  };
};

export type StorageUnitsQueryVariables = Exact<{
  pointId: Scalars['UUID']['input'];
}>;

export type StorageUnitsQuery = {
  __typename?: 'Query';
  storageUnits: Array<{
    __typename?: 'GqlStorageUnitListDto';
    isEmpty: boolean;
    allocationFrom?: string | null;
    allocationTo?: string | null;
    trackingIdentifier?: string | null;
    phoneNumber?: string | null;
    requiredStorageUnitFeatures: Array<StorageUnitFeature>;
    storageUnit: {
      __typename?: 'StorageUnitDto';
      id: string;
      name?: string | null;
      storageUnitStatus: StorageUnitStatus;
      features: Array<StorageUnitFeature>;
      lock?: { __typename?: 'LockDto'; lastStatus: LockStatus } | null;
      storageUnitSize?: {
        __typename?: 'StorageUnitSizeDto';
        isServiceModule: boolean;
      } | null;
    };
  } | null>;
};

export type TenantsQueryVariables = Exact<{
  pointId: Scalars['UUID']['input'];
}>;

export type TenantsQuery = {
  __typename?: 'Query';
  tenants: Array<{
    __typename?: 'TenantDto';
    projectId: string;
    tenantSubjectId: string;
    tenantName?: string | null;
    tenantServiceType: ServiceTypeEnum;
  } | null>;
};

export type StorageUnitStateChangedSubscriptionVariables = Exact<{
  pointId: Scalars['UUID']['input'];
}>;

export type StorageUnitStateChangedSubscription = {
  __typename?: 'Subscription';
  storageUnitStateChanged?: {
    __typename?: 'StorageUnitSubscriptionDto';
    id: string;
    label: string;
    state: LockStatus;
  } | null;
};

export const StorageUnitListFragmentDoc = `
    fragment StorageUnitList on GqlStorageUnitListDto {
  isEmpty
  allocationFrom
  allocationTo
  trackingIdentifier
  phoneNumber
  storageUnit {
    id
    name
    lock {
      lastStatus
    }
    storageUnitStatus
    storageUnitSize {
      isServiceModule
    }
    features
  }
  trackingIdentifier
  requiredStorageUnitFeatures
}
    `;
export const AllocationDtoFragmentDoc = `
    fragment AllocationDto on AllocationDto {
  id
  storageUnitId
  status
  content {
    id
    storedAt
    trackingIdentifiers {
      identifier
    }
  }
  reservation {
    id
    tenantSubjectId
    isBlockingReservation
    isDeliverySubmission
    blockingReservationReasonDescription
    blockingReservationReason
    serviceType
    languageCode
    status
    pickupDue
    createdAt
    cancelledAt
    duration
    expiration
    updatedAt
    expiredAt
    terminatedAt
    finishedAt
    from
    to
    accessCredentials {
      id
      phoneNumber
    }
    requiredStorageUnitFeatures
  }
}
    `;
export const StorageUnitSizeFragmentDoc = `
    fragment StorageUnitSize on StorageUnitSizeDto {
  isServiceModule
  innerWidth
  innerHeight
  innerDepth
}
    `;
export const StorageUnitDtoFragmentDoc = `
    fragment StorageUnitDto on GqlStorageUnitDto {
  hasReservation
  allocation {
    ...AllocationDto
  }
  storageUnit {
    id
    name
    lock {
      lastStatus
    }
    storageUnitStatus
    storageUnitSize {
      ...StorageUnitSize
    }
    features
  }
  company {
    name
  }
  phoneNumber
}
    `;
export const ChangeBlockingReservationTenantSubjectIdDocument = `
    mutation ChangeBlockingReservationTenantSubjectId($reservationId: UUID!, $tenantSubjectId: UUID!) {
  changeBlockingReservationTenantSubjectId(
    reservationId: $reservationId
    tenantSubjectId: $tenantSubjectId
  )
}
    `;

export const useChangeBlockingReservationTenantSubjectIdMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    ChangeBlockingReservationTenantSubjectIdMutation,
    TError,
    ChangeBlockingReservationTenantSubjectIdMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ChangeBlockingReservationTenantSubjectIdMutation,
    TError,
    ChangeBlockingReservationTenantSubjectIdMutationVariables,
    TContext
  >({
    mutationKey: ['ChangeBlockingReservationTenantSubjectId'],
    mutationFn: (
      variables?: ChangeBlockingReservationTenantSubjectIdMutationVariables
    ) =>
      fetcher<
        ChangeBlockingReservationTenantSubjectIdMutation,
        ChangeBlockingReservationTenantSubjectIdMutationVariables
      >(ChangeBlockingReservationTenantSubjectIdDocument, variables)(),
    ...options,
  });
};

export const ChangeStorageUnitStatusDocument = `
    mutation ChangeStorageUnitStatus($storageUnitId: UUID!, $status: StorageUnitStatus!, $statusChangeReason: String!) {
  changeStorageUnitStatus(
    storageUnitId: $storageUnitId
    status: $status
    statusChangeReason: $statusChangeReason
  ) {
    id
    storageUnitStatus
  }
}
    `;

export const useChangeStorageUnitStatusMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    ChangeStorageUnitStatusMutation,
    TError,
    ChangeStorageUnitStatusMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ChangeStorageUnitStatusMutation,
    TError,
    ChangeStorageUnitStatusMutationVariables,
    TContext
  >({
    mutationKey: ['ChangeStorageUnitStatus'],
    mutationFn: (variables?: ChangeStorageUnitStatusMutationVariables) =>
      fetcher<
        ChangeStorageUnitStatusMutation,
        ChangeStorageUnitStatusMutationVariables
      >(ChangeStorageUnitStatusDocument, variables)(),
    ...options,
  });
};

export const CreateBlockingReservationRequestV2Document = `
    mutation CreateBlockingReservationRequestV2($input: CreateBlockingReservationRequestInput!) {
  createBlockingReservationRequestV2(input: $input)
}
    `;

export const useCreateBlockingReservationRequestV2Mutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    CreateBlockingReservationRequestV2Mutation,
    TError,
    CreateBlockingReservationRequestV2MutationVariables,
    TContext
  >
) => {
  return useMutation<
    CreateBlockingReservationRequestV2Mutation,
    TError,
    CreateBlockingReservationRequestV2MutationVariables,
    TContext
  >({
    mutationKey: ['CreateBlockingReservationRequestV2'],
    mutationFn: (
      variables?: CreateBlockingReservationRequestV2MutationVariables
    ) =>
      fetcher<
        CreateBlockingReservationRequestV2Mutation,
        CreateBlockingReservationRequestV2MutationVariables
      >(CreateBlockingReservationRequestV2Document, variables)(),
    ...options,
  });
};

export const CreateDeliveryReservationDocument = `
    mutation CreateDeliveryReservation($request: CreateDeliveryReservationRequestInput!) {
  createDeliveryReservation(request: $request) {
    pinCode
  }
}
    `;

export const useCreateDeliveryReservationMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    CreateDeliveryReservationMutation,
    TError,
    CreateDeliveryReservationMutationVariables,
    TContext
  >
) => {
  return useMutation<
    CreateDeliveryReservationMutation,
    TError,
    CreateDeliveryReservationMutationVariables,
    TContext
  >({
    mutationKey: ['CreateDeliveryReservation'],
    mutationFn: (variables?: CreateDeliveryReservationMutationVariables) =>
      fetcher<
        CreateDeliveryReservationMutation,
        CreateDeliveryReservationMutationVariables
      >(CreateDeliveryReservationDocument, variables)(),
    ...options,
  });
};

export const ForcedOpenStorageUnitWithReservationDocument = `
    mutation ForcedOpenStorageUnitWithReservation($pointId: UUID!, $storageUnitId: UUID!) {
  forcedOpenStorageUnitWithReservation(
    pointId: $pointId
    storageUnitId: $storageUnitId
  )
}
    `;

export const useForcedOpenStorageUnitWithReservationMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    ForcedOpenStorageUnitWithReservationMutation,
    TError,
    ForcedOpenStorageUnitWithReservationMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ForcedOpenStorageUnitWithReservationMutation,
    TError,
    ForcedOpenStorageUnitWithReservationMutationVariables,
    TContext
  >({
    mutationKey: ['ForcedOpenStorageUnitWithReservation'],
    mutationFn: (
      variables?: ForcedOpenStorageUnitWithReservationMutationVariables
    ) =>
      fetcher<
        ForcedOpenStorageUnitWithReservationMutation,
        ForcedOpenStorageUnitWithReservationMutationVariables
      >(ForcedOpenStorageUnitWithReservationDocument, variables)(),
    ...options,
  });
};

export const GenerateStorageUnitOneTimePasswordDocument = `
    mutation GenerateStorageUnitOneTimePassword($pointId: UUID!, $storageUnitId: UUID!) {
  generateStorageUnitOneTimePassword(
    pointId: $pointId
    storageUnitId: $storageUnitId
  ) {
    oneTimePassword
    validTo
  }
}
    `;

export const useGenerateStorageUnitOneTimePasswordMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    GenerateStorageUnitOneTimePasswordMutation,
    TError,
    GenerateStorageUnitOneTimePasswordMutationVariables,
    TContext
  >
) => {
  return useMutation<
    GenerateStorageUnitOneTimePasswordMutation,
    TError,
    GenerateStorageUnitOneTimePasswordMutationVariables,
    TContext
  >({
    mutationKey: ['GenerateStorageUnitOneTimePassword'],
    mutationFn: (
      variables?: GenerateStorageUnitOneTimePasswordMutationVariables
    ) =>
      fetcher<
        GenerateStorageUnitOneTimePasswordMutation,
        GenerateStorageUnitOneTimePasswordMutationVariables
      >(GenerateStorageUnitOneTimePasswordDocument, variables)(),
    ...options,
  });
};

export const OpenEmptyStorageUnitDocument = `
    mutation OpenEmptyStorageUnit($pointId: UUID!, $storageUnitId: UUID!) {
  openEmptyStorageUnit(pointId: $pointId, storageUnitId: $storageUnitId)
}
    `;

export const useOpenEmptyStorageUnitMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    OpenEmptyStorageUnitMutation,
    TError,
    OpenEmptyStorageUnitMutationVariables,
    TContext
  >
) => {
  return useMutation<
    OpenEmptyStorageUnitMutation,
    TError,
    OpenEmptyStorageUnitMutationVariables,
    TContext
  >({
    mutationKey: ['OpenEmptyStorageUnit'],
    mutationFn: (variables?: OpenEmptyStorageUnitMutationVariables) =>
      fetcher<
        OpenEmptyStorageUnitMutation,
        OpenEmptyStorageUnitMutationVariables
      >(OpenEmptyStorageUnitDocument, variables)(),
    ...options,
  });
};

export const OpenEmptyStorageUnitsDocument = `
    mutation OpenEmptyStorageUnits($pointId: UUID!, $storageUnitIds: [UUID!]!) {
  openEmptyStorageUnits(pointId: $pointId, storageUnitIds: $storageUnitIds)
}
    `;

export const useOpenEmptyStorageUnitsMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    OpenEmptyStorageUnitsMutation,
    TError,
    OpenEmptyStorageUnitsMutationVariables,
    TContext
  >
) => {
  return useMutation<
    OpenEmptyStorageUnitsMutation,
    TError,
    OpenEmptyStorageUnitsMutationVariables,
    TContext
  >({
    mutationKey: ['OpenEmptyStorageUnits'],
    mutationFn: (variables?: OpenEmptyStorageUnitsMutationVariables) =>
      fetcher<
        OpenEmptyStorageUnitsMutation,
        OpenEmptyStorageUnitsMutationVariables
      >(OpenEmptyStorageUnitsDocument, variables)(),
    ...options,
  });
};

export const OpenServiceModuleDocument = `
    mutation OpenServiceModule($pointId: UUID!, $storageUnitId: UUID!) {
  openServiceModule(pointId: $pointId, storageUnitId: $storageUnitId)
}
    `;

export const useOpenServiceModuleMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    OpenServiceModuleMutation,
    TError,
    OpenServiceModuleMutationVariables,
    TContext
  >
) => {
  return useMutation<
    OpenServiceModuleMutation,
    TError,
    OpenServiceModuleMutationVariables,
    TContext
  >({
    mutationKey: ['OpenServiceModule'],
    mutationFn: (variables?: OpenServiceModuleMutationVariables) =>
      fetcher<OpenServiceModuleMutation, OpenServiceModuleMutationVariables>(
        OpenServiceModuleDocument,
        variables
      )(),
    ...options,
  });
};

export const OpenStorageUnitDocument = `
    mutation OpenStorageUnit($pointId: UUID!, $endUser: EndUserAccessPinInputDtoInput!, $isBlockingReservation: Boolean!, $storageUnitId: UUID, $projectId: UUID!, $serviceType: ServiceTypeEnum!, $tenantSubjectId: UUID, $singleTenantVerification: Boolean!) {
  openStorageUnit(
    pointId: $pointId
    endUser: $endUser
    storageUnitId: $storageUnitId
    isBlockingReservation: $isBlockingReservation
    projectId: $projectId
    serviceType: $serviceType
    tenantSubjectId: $tenantSubjectId
    singleTenantVerification: $singleTenantVerification
  ) {
    bindingFee
    storageUnitId
  }
}
    `;

export const useOpenStorageUnitMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    OpenStorageUnitMutation,
    TError,
    OpenStorageUnitMutationVariables,
    TContext
  >
) => {
  return useMutation<
    OpenStorageUnitMutation,
    TError,
    OpenStorageUnitMutationVariables,
    TContext
  >({
    mutationKey: ['OpenStorageUnit'],
    mutationFn: (variables?: OpenStorageUnitMutationVariables) =>
      fetcher<OpenStorageUnitMutation, OpenStorageUnitMutationVariables>(
        OpenStorageUnitDocument,
        variables
      )(),
    ...options,
  });
};

export const RequestMaintenanceStorageUnitDocument = `
    mutation RequestMaintenanceStorageUnit($pointId: UUID!, $storageUnitId: UUID!, $statusChangeReason: String!) {
  requestMaintenanceStorageUnit(
    pointId: $pointId
    storageUnitId: $storageUnitId
    statusChangeReason: $statusChangeReason
  )
}
    `;

export const useRequestMaintenanceStorageUnitMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    RequestMaintenanceStorageUnitMutation,
    TError,
    RequestMaintenanceStorageUnitMutationVariables,
    TContext
  >
) => {
  return useMutation<
    RequestMaintenanceStorageUnitMutation,
    TError,
    RequestMaintenanceStorageUnitMutationVariables,
    TContext
  >({
    mutationKey: ['RequestMaintenanceStorageUnit'],
    mutationFn: (variables?: RequestMaintenanceStorageUnitMutationVariables) =>
      fetcher<
        RequestMaintenanceStorageUnitMutation,
        RequestMaintenanceStorageUnitMutationVariables
      >(RequestMaintenanceStorageUnitDocument, variables)(),
    ...options,
  });
};

export const ResendSmsDocument = `
    mutation ResendSms($pointId: UUID!, $reservationId: UUID!) {
  resendSms(pointId: $pointId, reservationId: $reservationId)
}
    `;

export const useResendSmsMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ResendSmsMutation,
    TError,
    ResendSmsMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ResendSmsMutation,
    TError,
    ResendSmsMutationVariables,
    TContext
  >({
    mutationKey: ['ResendSms'],
    mutationFn: (variables?: ResendSmsMutationVariables) =>
      fetcher<ResendSmsMutation, ResendSmsMutationVariables>(
        ResendSmsDocument,
        variables
      )(),
    ...options,
  });
};

export const TerminateAllocationV2Document = `
    mutation TerminateAllocationV2($storageUnitId: UUID!) {
  terminateAllocationV2(storageUnitId: $storageUnitId)
}
    `;

export const useTerminateAllocationV2Mutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    TerminateAllocationV2Mutation,
    TError,
    TerminateAllocationV2MutationVariables,
    TContext
  >
) => {
  return useMutation<
    TerminateAllocationV2Mutation,
    TError,
    TerminateAllocationV2MutationVariables,
    TContext
  >({
    mutationKey: ['TerminateAllocationV2'],
    mutationFn: (variables?: TerminateAllocationV2MutationVariables) =>
      fetcher<
        TerminateAllocationV2Mutation,
        TerminateAllocationV2MutationVariables
      >(TerminateAllocationV2Document, variables)(),
    ...options,
  });
};

export const UpdateContentStateDocument = `
    mutation UpdateContentState($pointId: UUID!, $tenantSubjectId: UUID!, $trackingIdentifier: String, $state: UpdateContentStateEnum!, $contentId: UUID, $storageUnitId: UUID, $userId: UUID, $isPickedUpBySender: Boolean) {
  updateContentState(
    pointId: $pointId
    tenantSubjectId: $tenantSubjectId
    trackingIdentifier: $trackingIdentifier
    state: $state
    contentId: $contentId
    storageUnitId: $storageUnitId
    userId: $userId
    isPickedUpBySender: $isPickedUpBySender
  )
}
    `;

export const useUpdateContentStateMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateContentStateMutation,
    TError,
    UpdateContentStateMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateContentStateMutation,
    TError,
    UpdateContentStateMutationVariables,
    TContext
  >({
    mutationKey: ['UpdateContentState'],
    mutationFn: (variables?: UpdateContentStateMutationVariables) =>
      fetcher<UpdateContentStateMutation, UpdateContentStateMutationVariables>(
        UpdateContentStateDocument,
        variables
      )(),
    ...options,
  });
};

export const UpdateReservationDurationDocument = `
    mutation UpdateReservationDuration($reservationId: UUID!, $to: DateTime!) {
  updateReservationDuration(reservationId: $reservationId, to: $to)
}
    `;

export const useUpdateReservationDurationMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateReservationDurationMutation,
    TError,
    UpdateReservationDurationMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateReservationDurationMutation,
    TError,
    UpdateReservationDurationMutationVariables,
    TContext
  >({
    mutationKey: ['UpdateReservationDuration'],
    mutationFn: (variables?: UpdateReservationDurationMutationVariables) =>
      fetcher<
        UpdateReservationDurationMutation,
        UpdateReservationDurationMutationVariables
      >(UpdateReservationDurationDocument, variables)(),
    ...options,
  });
};

export const VerifyAccessPinDocument = `
    mutation VerifyAccessPin($pointId: UUID!, $storageUnitId: UUID!, $accessPin: String!, $projectId: UUID!, $serviceType: ServiceTypeEnum!, $tenantSubjectId: UUID!) {
  verifyAccessPin(
    pointId: $pointId
    storageUnitId: $storageUnitId
    tenantSubjectId: $tenantSubjectId
    accessPin: $accessPin
    projectId: $projectId
    serviceType: $serviceType
  ) {
    bindingFee
  }
}
    `;

export const useVerifyAccessPinMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    VerifyAccessPinMutation,
    TError,
    VerifyAccessPinMutationVariables,
    TContext
  >
) => {
  return useMutation<
    VerifyAccessPinMutation,
    TError,
    VerifyAccessPinMutationVariables,
    TContext
  >({
    mutationKey: ['VerifyAccessPin'],
    mutationFn: (variables?: VerifyAccessPinMutationVariables) =>
      fetcher<VerifyAccessPinMutation, VerifyAccessPinMutationVariables>(
        VerifyAccessPinDocument,
        variables
      )(),
    ...options,
  });
};

export const AppConfigDocument = `
    query AppConfig($pointId: UUID!) {
  appConfig(pointId: $pointId) {
    isPointOnline
    callCenterPhoneNumber
    pointFullName
    projectId
  }
}
    `;

export const useAppConfigQuery = <TData = AppConfigQuery, TError = unknown>(
  variables: AppConfigQueryVariables,
  options?: Omit<UseQueryOptions<AppConfigQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<AppConfigQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<AppConfigQuery, TError, TData>({
    queryKey: ['AppConfig', variables],
    queryFn: fetcher<AppConfigQuery, AppConfigQueryVariables>(
      AppConfigDocument,
      variables
    ),
    ...options,
  });
};

export const StorageUnitSizesDocument = `
    query StorageUnitSizes {
  storageUnitSizes {
    ...StorageUnitSize
  }
}
    ${StorageUnitSizeFragmentDoc}`;

export const useStorageUnitSizesQuery = <
  TData = StorageUnitSizesQuery,
  TError = unknown,
>(
  variables?: StorageUnitSizesQueryVariables,
  options?: Omit<
    UseQueryOptions<StorageUnitSizesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      StorageUnitSizesQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<StorageUnitSizesQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['StorageUnitSizes']
        : ['StorageUnitSizes', variables],
    queryFn: fetcher<StorageUnitSizesQuery, StorageUnitSizesQueryVariables>(
      StorageUnitSizesDocument,
      variables
    ),
    ...options,
  });
};

export const StorageUnitDocument = `
    query StorageUnit($pointId: UUID!, $storageUnitId: UUID!) {
  storageUnit(pointId: $pointId, storageUnitId: $storageUnitId) {
    ...StorageUnitDto
  }
}
    ${StorageUnitDtoFragmentDoc}
${AllocationDtoFragmentDoc}
${StorageUnitSizeFragmentDoc}`;

export const useStorageUnitQuery = <TData = StorageUnitQuery, TError = unknown>(
  variables: StorageUnitQueryVariables,
  options?: Omit<
    UseQueryOptions<StorageUnitQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<StorageUnitQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<StorageUnitQuery, TError, TData>({
    queryKey: ['StorageUnit', variables],
    queryFn: fetcher<StorageUnitQuery, StorageUnitQueryVariables>(
      StorageUnitDocument,
      variables
    ),
    ...options,
  });
};

export const StorageUnitsDocument = `
    query StorageUnits($pointId: UUID!) {
  storageUnits(pointId: $pointId) {
    ...StorageUnitList
  }
}
    ${StorageUnitListFragmentDoc}`;

export const useStorageUnitsQuery = <
  TData = StorageUnitsQuery,
  TError = unknown,
>(
  variables: StorageUnitsQueryVariables,
  options?: Omit<
    UseQueryOptions<StorageUnitsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<StorageUnitsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<StorageUnitsQuery, TError, TData>({
    queryKey: ['StorageUnits', variables],
    queryFn: fetcher<StorageUnitsQuery, StorageUnitsQueryVariables>(
      StorageUnitsDocument,
      variables
    ),
    ...options,
  });
};

export const TenantsDocument = `
    query Tenants($pointId: UUID!) {
  tenants(pointId: $pointId) {
    projectId
    tenantSubjectId
    tenantName
    tenantServiceType
  }
}
    `;

export const useTenantsQuery = <TData = TenantsQuery, TError = unknown>(
  variables: TenantsQueryVariables,
  options?: Omit<UseQueryOptions<TenantsQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<TenantsQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<TenantsQuery, TError, TData>({
    queryKey: ['Tenants', variables],
    queryFn: fetcher<TenantsQuery, TenantsQueryVariables>(
      TenantsDocument,
      variables
    ),
    ...options,
  });
};

export const StorageUnitStateChangedDocument = `
    subscription StorageUnitStateChanged($pointId: UUID!) {
  storageUnitStateChanged(pointId: $pointId) {
    id
    label
    state
  }
}
    `;
